/* eslint-disable quotes */
import { Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router';
import PropTypes from 'prop-types';
import { currentFeatureSet, isEnabled } from '@selectors/featureSet';
import { isMultifieldRecommendationLoad } from '@selectors/headerSelectors';
import { useSaveSpotCheck } from '@utils/featureConfigHooks';
import { ConnectedNotificationMenu } from './notificationDropdown';
import { StyledHeader } from './styles';
import { SeasonSelect } from './SeasonSelect';
import { SolutionSelect } from './SolutionSelect';
import { UserProfileSelect } from './userProfileHeader';
import { PropertySelect } from './propertyHeader';
import { OrgSelect } from './orgHeader';
import { ResellerSelect } from './resellerHeader';
import { DeckApp } from './deckApp';
import { UKLogoIcon } from '../../common/UKlogo';
import CropwiseProductLogo from './assets/CropwiseProductLogo.png';

export const CollapsedHeader = (props) => {
  const {
    currentOrg,
    currentPage,
    currentReseller,
    handleLogout,
    isAllFieldLoaded,
    isRoleAdmin,
    isRoleManager,
    orgs,
    properties,
    propertyId,
    resellersList,
    setCurrentOrg,
    setCurrentOrgObject,
    setCurrentProp,
    setCurrentReseller,
    setLatLongorgReset,
    setOrgListForDashboard,
    setSourceIdForOrg,
    sourceIDList,
    userProfile
  } = props;
  const isRecommendationLoad = useSelector(isMultifieldRecommendationLoad);
  const featureSet = useSelector(currentFeatureSet);
  const canSaveSpotCheck = useSaveSpotCheck();
  const [filteredOrgs, setFilteredOrgs] = useState(orgs);
  const selectedOrg = props.currentOrg;

  const selectedProperty = useMemo(() => {
    return properties.find((p) => p.id === propertyId);
  }, [properties, propertyId]);

  useEffect(() => {
    setFilteredOrgs(orgs);
  }, [orgs]);
  const checkManage = useMemo(() => {
    const isActiveReseller = currentReseller?.name !== '';
    return (isRoleManager && isActiveReseller) || !isRoleManager;
  }, [currentReseller, isRoleManager]);

  const useSeasons = useMemo(() => {
    return isEnabled('SolutionsBySeason', featureSet) || isEnabled('Seasons', featureSet);
  }, [featureSet]);
  let showUkLogo = false;
  if (window.location.host.indexOf('uk') > -1) {
    showUkLogo = true;
  }

  return (
    <StyledHeader>
      <div onKeyUp={() => {}}>
        <DeckApp selectedOrg={currentOrg} userProfile={userProfile} />
      </div>
      {showUkLogo ? (
        <div className='Collapselogo'>
          <UKLogoIcon />
        </div>
      ) : (
        <div className='Collapselogo' style={{ margin: '7px' }}>
          <img src={CropwiseProductLogo} alt='crowise logo' />
        </div>
      )}
      {/* Reseller Selector*/}
      {isRoleManager && (
        <ResellerSelect
          resellersList={resellersList}
          setLatLongorgReset={setLatLongorgReset}
          setCurrentReseller={setCurrentReseller}
          setOrgListForDashboard={setOrgListForDashboard}
          currentReseller={currentReseller}
          setCurrentOrg={setCurrentOrg}
          filterOrgs={filteredOrgs}
        />
      )}

      {/* Organization Selector*/}
      {checkManage && !isRoleAdmin && (
        <OrgSelect
          currentOrg={currentOrg}
          isRecommendationLoad={isRecommendationLoad}
          isAllFieldLoaded={isAllFieldLoaded}
          filteredOrgs={filteredOrgs}
          currentPage={currentPage}
          setCurrentOrg={setCurrentOrg}
          setCurrentOrgObject={setCurrentOrgObject}
          setSourceIdForOrg={setSourceIdForOrg}
          sourceIDList={sourceIDList}
        />
      )}

      {/* Property Selector*/}
      {selectedProperty !== undefined && (
        <PropertySelect
          setCurrentProp={setCurrentProp}
          currentOrg={currentOrg}
          isAllFieldLoaded={isAllFieldLoaded}
          isRecommendationLoad={isRecommendationLoad}
          selectedProperty={selectedProperty}
          properties={properties}
        />
      )}
      {/* The Seasons Selector*/}
      {useSeasons && <SeasonSelect isAllFieldLoaded={isAllFieldLoaded} selectedOrg={selectedOrg} />}
      {/* the Seed Selector solutions selection, only shown when on the seed selector page or the spot check page with spot check saving enabled */}
      {useSeasons && (
        <Routes>
          <Route path={'/crop/*'} element={<SolutionSelect currentPage={currentPage} />} />
          <Route
            path={'/spot-check/*'}
            element={canSaveSpotCheck && <SolutionSelect currentPage={currentPage} />}
          />
        </Routes>
      )}

      <Row
        className='popoverabc'
        type='flex'
        justify='space-between'
        align='middle'
        style={{
          padding: '5px 10px',
          float: 'right',
          marginLeft: 'auto'
        }}
      >
        {/* Notification Selector*/}
        {isEnabled('Notifications', featureSet) && <ConnectedNotificationMenu />}
        {/* User profile Selector*/}
        {<UserProfileSelect userProfile={userProfile} handleLogout={handleLogout} />}
      </Row>
    </StyledHeader>
  );
};

CollapsedHeader.propTypes = {
  orgs: PropTypes.array.isRequired,
  properties: PropTypes.array.isRequired,
  propertyId: PropTypes.string,
  handleLogout: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
  currentOrg: PropTypes.object.isRequired,
  currentReseller: PropTypes.object.isRequired,
  setLatLongorgReset: PropTypes.func,
  sourceIDList: PropTypes.array,
  setSourceIdForOrg: PropTypes.func,
  currentPage: PropTypes.any,
  isAllFieldLoaded: PropTypes.any,
  isRoleAdmin: PropTypes.any,
  isRoleManager: PropTypes.any,
  resellersList: PropTypes.any,
  setCurrentOrg: PropTypes.any,
  setCurrentOrgObject: PropTypes.any,
  setCurrentProp: PropTypes.any,
  setCurrentReseller: PropTypes.any,
  setOrgListForDashboard: PropTypes.any
};
