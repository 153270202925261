/* eslint-disable no-console */
/* eslint-disable prettier/prettier */ // indentation conflict with eslint
import { Menu } from 'antd';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { isEnabled, currentFeatureSet } from '@selectors/featureSet';
import { useZendesk, openZendeskWidget } from '@utils/useZendesk';
import { getCurrentPage } from '@utils/currentPageHelper';
import { SiderStyle, VersionStyle } from './mainSiderStyle';
import {
  DashboardSVGIcon,
  CropsSVGIcon,
  SeedSolutionSVGIcon,
  SeedSelectorSVGIcon,
  SpotCheckSVGIcon,
  AssociateSVGIcon,
  SpportSVGIcon,
  AdminSVGIcon,
  ProductCatalougeSVGIcon
} from './menuIcons';
import collapseIcon from './assets/collapseIcon.png';
import expandIcon from './assets/expandIcon.png';

export const MainSider = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const [collapsed, setCollapsed] = useState(true);
  const featureSet = useSelector(currentFeatureSet);

  const pathName = window.location.pathname;
  const envURL = window.location.href;
  const envURLContains = envURL.includes('production');
  let currentPath = null;
  let manageOrg = '/app/dealer';
  const landingOrganization = location.pathname.indexOf('landing-organization');
  if (landingOrganization > -1) {
    currentPath = '/app/dealer';
  } else {
    currentPath = getCurrentPage(location, params, 'path');
  }

  if (props.isRoleAdmin) {
    currentPath = '/support/admin';
  }

  const { zendeskEnabled } = useZendesk();
  const getProperBuildNumber = () => {
    const version = process.env.BUILD_VERSION;
    if (version && version.indexOf('from') > -1) {
      let lastBranch = version.split('from syngenta-digital/');
      return `${lastBranch[1]}`;
    }
    return version;
  };
  const items = [
    {
      isActive: props.isRoleManager,
      icon: <DashboardSVGIcon />,
      key: '/app/manager',
      label: (
        <Link to={{ pathname: '/app/manager' }}>
          <span>{t('Manager')}</span>
        </Link>
      )
    },
    {
      'data-testid': 'manageOrgPage',
      isActive: isEnabled('ManageOrganization', featureSet) && !props.isRoleAdmin,
      key: manageOrg,
      label: (
        <Link to={{ pathname: '/app/dealer' }}>
          <span>{t('Manage Organization')}</span>
        </Link>
      ),
      icon: <CropsSVGIcon />
    },
    {
      'data-testid': 'seedSelectorPage',
      isActive: !envURLContains && !props.isRoleAdmin && isEnabled('SeedSelector', featureSet),
      icon: <SeedSelectorSVGIcon />,
      key: '/app/crop',
      label: (
        <Link to={{ pathname: '/app/crop' }}>
          <span>{t('Seed Selector')}</span>
        </Link>
      )
    },
    {
      'data-testid': 'seedSolutionPage',
      isActive:
        !envURLContains &&
        !props.isRoleAdmin &&
        (isEnabled('SolutionsBySeason', featureSet) || isEnabled('Solutions', featureSet)),
      icon: <SeedSolutionSVGIcon />,
      key: '/app/solutions',
      label: (
        <Link to={{ pathname: '/app/solutions' }}>
          <span>{t('Seed Solution')}</span>
        </Link>
      )
    },
    {
      'data-testid': 'spotcheckPage',
      isActive: !props.isRoleAdmin && isEnabled('SpotCheck', featureSet),
      icon: <SpotCheckSVGIcon />,
      key: '/app/spot-check',
      label: (
        <Link to={{ pathname: '/app/spot-check' }}>
          <span>{t('Spot Check')}</span>
        </Link>
      )
    },
    {
      'data-testid': 'associatePage',
      isActive: props.isAllowedForSetting,
      icon: <AssociateSVGIcon />,
      key: '/support/associate',
      label: (
        <Link to='/support/associate'>
          <span>{t('Associate')}</span>
        </Link>
      )
    },
    {
      'data-testid': 'adminPage',
      isActive: props.isAllowedForSetting,
      icon: <AdminSVGIcon />,
      key: '/support/admin',
      label: (
        <Link to='/support/admin'>
          <span>{t('Admin')}</span>
        </Link>
      )
    },
    {
      'data-testid': 'productCatalougePage',
      isActive: props.isAllowedForSetting,
      icon: <ProductCatalougeSVGIcon />,
      key: '/support/productcatalouge',
      label: (
        <Link to='/support/productcatalouge'>
          <span>{t('Product Catalouge')}</span>
        </Link>
      )
    }
    // {
    //   'data-testid': 'supportPage',
    //   isActive: !props.isRoleAdmin && zendeskEnabled,
    //   icon: <SpportSVGIcon />,
    //   key: 'myLauncher',
    //   label:
    //     // eslint-disable-next-line no-script-url
    //     <a href={'javascript:void(0)'} id='myLauncher' onClick={openZendeskWidget}>
    //       <span>{t('Support')}</span>
    //     </a>
    // }
  ];
  useEffect(() => {
    const updateAppHeight = () => {
      const appHeight = window.innerHeight;
      const visualHeight = window.visualViewport.height;

      // If keyboard is open (viewport height is reduced), keep original app height
      const adjustedHeight = visualHeight < appHeight ? appHeight : visualHeight;

      document.documentElement.style.setProperty('--app-height', `${adjustedHeight}px`);
    };

    window.addEventListener('resize', updateAppHeight);
    updateAppHeight();

    // Clean up event listener
  }, []);
  const getMenuItems = () => {
    return (
      items
        // Remove non-active tabs
        .filter((item) => item.isActive)
        // Remove isActive property as it is not an allowed prop on item.
        // eslint-disable-next-line no-unused-vars
        .map(({ isActive, ...rest }) => rest)
    );
  };
  return (
    <SiderStyle
      className='MainSider'
      width={250}
      collapsedWidth={64}
      collapsible
      collapsed={collapsed}
      trigger={null}
      style={{ zIndex: '10000' }}
    >
      <div>
        <Menu
          selectable={true}
          selectedKeys={[currentPath]}
          defaultSelectedKeys={[pathName]}
          mode='inline'
          items={getMenuItems()}
        />
        <div
          style={{
            position: 'absolute',
            bottom: 30,
            margin: '10px',
            height: '48px',
            width: !collapsed ? '240px' : '0px'
          }}
        >
          {!collapsed && <VersionStyle>{getProperBuildNumber()}</VersionStyle>}
        </div>
        <div
          id='menuicon_collapse'
          className={collapsed ? 'collapsed' : 'not_collapsed'}
          style={{
            bottom: 20,
            position: 'absolute',
            marginLeft: 10
          }}
          onClick={() => {
            setCollapsed(!collapsed);
          }}
          onKeyUp={() => {}}
        >
          {collapsed ? (
            <img src={expandIcon} alt='expand' style={{ marginLeft: 5 }} />
          ) : (
            <img src={collapseIcon} alt='collapse' style={{ marginLeft: 190 }} />
          )}
        </div>
      </div>
    </SiderStyle>
  );
};
MainSider.propTypes = {
  isRoleManager: PropTypes.bool,
  isRoleAdmin: PropTypes.bool,
  isAllowedForSetting: PropTypes.bool
};
