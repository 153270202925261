import { Layout } from 'antd';
import styled from 'styled-components';

const { Sider } = Layout;

export const SiderStyle = styled(Sider)`
  height: calc(var(--app-height, 100vh) - 64px);
  bottom: 0;
  background: #2f3031;
  left: 0;
  overflow: hidden;
  z-index: 999 !important;
  position: absolute;

  .ant-layout-sider-children {
    position: relative;
  }

  #myLauncher {
    color: #606263;
  }
  .Collapselogo {
    height: 82px;
    margin: 14px;
    transition: all 0.5s;
  }
  .ant-menu-item a {
    color: #707374;
  }
  ant-menu-light .ant-menu-item:hover,
  .ant-menu-item:hover {
    color: #ffffff;
    background: #232324;
  }
  .ant-menu {
    background: #2f3031;
  }

  .ant-menu-vertical > .ant-menu-item,
  .ant-menu-inline > .ant-menu-item {
    height: 54px;
    margin: 0;
  }
  .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin: 0;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #232324;
    background: #232324;
  }
  .ant-menu {
    color: #707374;
  }
  .ant-menu-item-selected {
    color: #fff;
  }
  .ant-menu-item-selected > a > span {
    color: #ffffff;
    cursor: pointer;
  }
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
    left: -4px;
  }
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon {
    display: block;
    line-height: 60px;
  }
  .ant-menu-item > span > a {
    line-height: 40px;
    height: 100%;
    display: block;
  }
  .ant-menu-inline .ant-menu-item {
    padding: 15px 0px 15px 10px !important;
  }
  .ant-menu-item > ant-menu-item-icon {
    height: 100%;
    line-height: 52px;
    display: block;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: rgb(35, 35, 36);
  }
  .ant-menu-item-selected > span > a,
  .ant-menu-item:hover > span > a {
    color: #fff;
    background: transparent;
  }
  .ant-menu-item:hover > span > svg > path,
  .ant-menu-item-selected > span > svg > path {
    //stroke: #fff;
    //fill: #fff;
    background: transparent;
  }

  .ant-layout-sider-trigger {
    position: fixed;
    bottom: 0;
    z-index: 1;
    height: 48px;
    color: #fff;
    text-align: center;
    background: #2f3031;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .ant-layout-sider-has-trigger {
    padding-bottom: 48px;
  }
  .ant-menu-item-selected a:hover {
    color: #fff;
  }
`;
export const VersionStyle = styled.div`
  font-size: 14px;
  height: auto;
  display: flex;
`;
